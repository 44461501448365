import React from "react";
import "../styles/App.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Button } from "../components/Button";
import faq from "../images/img-9.jpg";

export default function Faq() {
  return (
    <>
      <NavBar
        title="Frequently Asked Questions | Point Blank Moving"
        description="Point Blank Moving the best local moving company in KC. Kansas City's premier, five star moving company! Find frequently asked questions and concerns for local moving."
      />
      <div
        className="landing-container"
        style={{
          backgroundImage: `url(${faq})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <h1 style={{ textShadow: "0 0 5px black, 0 0 5px black" }}>FAQ</h1>
        <div className="landing-btns">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            path="/Quote"
          >
            Check Availability
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--large"
            path="/Contact"
          >
            GET IN CONTACT
          </Button>
        </div>
      </div>
      <div style={{ backgroundColor: "white", padding: "50px" }}>
        <div className="content">
          <br />
          <h2 style={{ paddingTop: "20px", textAlign: "center" }}>
            What should the customer know about our pricing (e.g., discounts,
            fees)?
          </h2>
          <div className="content-about">
            Military discount - We are a veteran owned business and we believe
            in taking care of those who serve. We offer a 5% discount off our
            hourly labor rate for honorably discharged veterans and current
            active duty or reserve service members.
          </div>
          <br />
          <br />
        </div>
        <br />
        <br />
        <div className="content">
          <br />
          <h2 style={{ paddingTop: "20px", textAlign: "center" }}>
            What types of customers have you worked with?
          </h2>
          <div className="content-about">
            We work with all types and sizes of jobs. We’ve moved military
            families, high end custom homes, small apartments, restaurants,
            retail businesses and offices —you name it. Basically if you have
            things to move we get it done!
          </div>
          <br />
          <br />
        </div>
        <br />
        <br />
        <div className="content">
          <br />
          <h2 style={{ paddingTop: "30px", textAlign: "center" }}>
            What questions should customers think through before talking to
            professionals about their project?
          </h2>
          <div className="content-about">
            <strong>Question:</strong> What do you do to prevent the spread of
            COVID - 19?
            <br />
            <br />
            <strong>Answer:</strong> Temperature checking before jobs (no sick
            movers period.). Movers wear FRESH gloves throughout your move.
            (Hand sanitizer and sanitizer wipes on our truck as well.) Zero
            contact payment process; we send you a text with a link and you pay
            with your card from your phone.
          </div>
          <br />
          <br />
        </div>
      </div>
      <Footer />
    </>
  );
}
